



.tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    border-top: none;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    height: 100%;
    width: 100%;
}

.nav.nav-tabs {
    margin-bottom: 0 !important;
}

.left-pane-tab-container {
    min-height: fit-content;
}
/* prevent wrapping of nav items */

/* .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
} */


.nav-tabs {
    --color1: #c1c5c7;
    --color2: #14a8b3;
    --color3: #d0d4d6;
    --bs-nav-tabs-border-width: 1px;
    --bs-nav-tabs-border-color: var(--color1);
    --bs-nav-tabs-border-radius: 0.375rem;
    --bs-nav-tabs-link-hover-border-color: var(--color1) var(--color1) var(--color1);
    --bs-nav-tabs-link-active-color: var(--color2);
    --bs-nav-tabs-link-active-bg: #fff;
    --bs-nav-tabs-link-active-border-color: var(--color3) var(--color1) #fff;
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
    
    
}

.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: #094e8f;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
